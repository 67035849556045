import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
import { ArrowDown, ArrowRight, Check, Search } from "react-feather"
import HelmetHead from "../../components/HelmetHead"
import React, { useContext, useEffect, useState } from "react"
// import DWChart from "react-datawrapper-chart"

import Loadable from "@loadable/component"
import { graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import TimeSchedule from "../../svgs/icons/duotone-icons/Code/Time-schedule.svg"
import Group from "../../svgs/icons/duotone-icons/Communication/Group.svg"
import Euro from "../../svgs/icons/duotone-icons/Shopping/Euro.svg"
import Curve3 from "../../svgs/shapes/curves/curve-3.svg"
const CTA2 = Loadable(() => import("../../components/CTA2"))
const Newsletter = Loadable(() => import("../../components/Newsletter"))
import BackgroundSection from "../../components/BackgroundSection"
import smoothScroll from "../../components/smoothScroll"
import LayoutContext from "../../components/LayoutContext"

const LoadableIntroVideo = Loadable(() => import("../../components/IntroVideo"))

export const query = graphql`
  query {
    allStrapiCity(filter: { country: { eq: "Schweiz" } }) {
      edges {
        node {
          id
          city
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, layout: FULL_WIDTH)
              }
            }
          }
          slug
          title
        }
      }
    }
  }
`

const ImmobilienmaklerSchweiz = ({ data }) => {
  //define if lead-generator is used in page!
  const canonicalPathname = `/de/immobilienmakler-schweiz/`

  const cities = data.allStrapiCity.edges

  const [zip, setZip] = useState("")
  const handleSearchImmoLeadZip = event => {
    event.preventDefault()

    navigate(`/de/immobilienbewertung/?city=${zip}`)
  }

  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {},
    }))
  }, [])

  return (
    <>
      <HelmetHead
        title="Immobilienmakler in der Schweiz: Die besten Makler passend zur Immobilie"
        description="Sie suchen einen professionellen Makler in der Schweiz? Passend zu Ihrer Immobilie? Wir empfehlen Ihnen die besten Makler in den beliebtesten Großstädten."
        keywords="Immobilienmakler in der Schweiz"
        links={[
          {
            rel: "canonical",
            href: `${process.env.GATSBY_SITE_URL}${canonicalPathname}`,
          },
        ]}
      />
      <BackgroundSection src="Schweiz.jpg" className="bg-cover">
        <div className="container">
          <div
            className="row justify-content-center align-items-end pb-6"
            style={{ minHeight: "90vh" }}
          >
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1 className="display-2 fw-bold text-white text-shadow-custom">
                Makler&shy;zentrale
                <br />
                für die Schweiz
              </h1>
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 py-md-9 border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="immobilienmakler-schweiz" />
              <a
                href="#immobilienmakler-schweiz"
                className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
            </div>
          </div>

          <p className="fs-lg text-muted">
            <b>Ganz egal ob Haus, Wohnung oder Grundstück:</b> Ein
            Immobilienmakler hilft Ihnen als Eigentümer dabei, Ihre{" "}
            <u>
              <Link to="/de/immobilie-verkaufen/">Immobilie zu verkaufen</Link>
            </u>
            . Ebenso unterstützt er Interessenten dabei, die passende Immobilie
            zu finden.
          </p>
          <p className="fs-lg text-muted">
            <b>Vor allem in den beliebten Großstädten</b> der Schweiz gestaltet
            sich der Immobilienmarkt immer komplexer, sodass das Hinzuziehen
            eines Experten quasi nicht mehr wegzudenken ist.
          </p>
          <p className="fs-lg text-muted">
            <b>
              Doch wie soll man sich für einen Immobilienmakler entscheiden?
            </b>{" "}
            Die Dichte an Immobilienmaklern ist so hoch, dass die Auswahl
            schwerfällt. Wir von der Maklerzentrale für die Schweiz helfen Ihnen
            dabei, den passenden Makler zu ihrer Immobilie zu finden:
            kostenfrei, zuverlässig und unabhängig.
          </p>
        </div>
      </section>

      <section className="py-8 py-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <h2 className="fw-bold">
            Immobilienmakler in Großstädten der Schweiz
          </h2>
          <p className="fs-lg text-muted">
            Der Immobilienkauf in Großstädten gewinnt an immer größerer
            Beliebtheit. Durch die steigende Nachfrage ist aktuell auch mit
            hohen Preisen zu rechnen. In jedem Fall lohnt es sich für beide
            Parteien, einen Immobilienmakler mit der entsprechenden Expertise
            und Marktkenntnis einzuschalten. So schöpfen Sie das maximale
            Potential aus Ihrem Kauf- bzw. Verkauf und überlassen nichts dem
            Zufall.
          </p>
          <p className="fs-lg text-muted fw-bold">
            Wir sind in ganz Schweiz unterwegs, um Immobilienmakler besser
            kennenzulernen und somit eine erstklassige Maklerempfehlung zu
            gewährleisten.
          </p>

          <div className="scroll-beacon" id="alle-regionen" />
          {cities.length ? (
            <h3 className="fw-bold mt-7 mt-md-9 mb-5">
              Immobilienmakler in der Schweiz
            </h3>
          ) : null}

          <div className="row g-0">
            {cities.map(({ node: city }) => (
              <div className="col-md-6 col-lg-4 p-2" key={city.id}>
                <div
                  className="card card-row shadow-light-lg bg-cover text-white lift lift-lg overlay city-card-overlay"
                  style={{
                    backgroundImage: `url(${city.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src})`,
                  }}
                >
                  <Link
                    className="card-body px-2 pb-0 pt-12"
                    to={`/de/${city.slug}/`}
                    language="de"
                  >
                    <h4 dangerouslySetInnerHTML={{ __html: city.title }} />
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/*<div*/}
          {/*  className="scroll-beacon"*/}
          {/*  id="immobilienpreise"*/}
          {/*/>*/}
          {/*<h3 className="fw-bold mt-7 mt-md-9 mb-5">*/}
          {/*  Immobilienpreise in der Schweiz*/}
          {/*</h3>*/}

          {/*<form*/}
          {/*  className="rounded shadow mb-lg-n10"*/}
          {/*  style={{ maxWidth: "400px" }}*/}
          {/*  onSubmit={handleSearchImmoLeadZip}*/}
          {/*  data-sal="slide-up"*/}
          {/*  data-sal-delay="800"*/}
          {/*  data-sal-duration="700"*/}
          {/*>*/}
          {/*  <div className="input-group input-group-md">*/}
          {/*    <span className="input-group-text border-0 pe-2">*/}
          {/*      <Search />*/}
          {/*    </span>*/}

          {/*    <input*/}
          {/*      type="text"*/}
          {/*      className="form-control border-0 px-1"*/}
          {/*      aria-label="PLZ / Ort eingeben"*/}
          {/*      placeholder="PLZ / Ort eingeben"*/}
          {/*      value={zip}*/}
          {/*      onChange={e => setZip(e.target.value)}*/}
          {/*    />*/}

          {/*    <span className="input-group-text border-0 py-0 ps-1 pe-1">*/}
          {/*      <button type="submit" className="btn btn-sm btn-primary">*/}
          {/*        Suchen*/}
          {/*      </button>*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*</form>*/}

          {/*<DWChart*/}
          {/*  title="Chart"*/}
          {/*  src="https://datawrapper.dwcdn.net/BAZqG/7/"*/}
          {/*  style={{ height: "80vh" }}*/}
          {/*/>*/}
        </div>
      </section>

      <CTA2>
        <blockquote className="blockquote">
          <p className="h2 text-white">
            Sie möchten Ihre Immobilie verkaufen?
            <br />
            Wir geben Ihre Immobilie in gute Hände.
          </p>
        </blockquote>
      </CTA2>

      <section className="py-8 py-md-11 border-bottom">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <span className="badge rounded-pill bg-success-soft mb-3">
                <span className="h6 text-uppercase">Immobilienmakler?</span>
              </span>

              <h2 className="fw-bold">Dafür lohnt sich ein Immobilienmakler</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 d-md-none d-lg-block">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Group />
              </div>
              <h3 className="fw-bold text-center">
                Sie profitieren von der Marktkenntnis & Expertise
              </h3>
              <p className="text-muted">
                Eine sehr gute{" "}
                <b>regionale Marktkenntnis und entsprechendes Fachwissen</b>{" "}
                kann Ihnen nur ein erfahrender Immobilien­makler bieten. Doch
                das sind die zentralen Aspekte für einen{" "}
                <u>
                  <Link to="/de/immobilie-verkaufen/">
                    erfolgreichen Immobilienverkauf
                  </Link>
                </u>
                ! Ein Makler kennt die regionale Nachfrage und ist immer auf dem
                aktuellen Stand, was Trends, neue Objekte und
                Preis­entwicklungen betrifft. Zudem profitieren Sie von seinem{" "}
                <b>breiten Kundenstamm</b> an Interessenten in der Region.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <TimeSchedule />
              </div>
              <h3 className="fw-bold text-center">
                Zeit & Aufwand werden häufig unterschätzt
              </h3>
              <p className="text-muted">
                Der Verkauf einer Immobilie ist für Sie als Laie mit einem sehr
                hohen Zeitaufwand verbunden. Denn eine Expertise in den
                Bereichen{" "}
                <u>
                  <Link to="/de/immobilienbewertung/">Immobilienbewertung</Link>
                </u>
                , Marktkenntnis, rechtlichen Sicherheit, Verkaufs­gespräche und
                Verträge, müssen Sie sich in <b>mühsamer Recherche</b> zunächst
                selbst erarbeiten. Hinzu kommen die{" "}
                <b>zeitintensiven Besichtigungen</b> inkl. Vor- und
                Nachbereitung. Ein Profi wird Ihnen hier viel Zeit und Nerven
                ersparen.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Euro />
              </div>
              <h3 className="fw-bold text-center">
                Er bewahrt Sie vor teuren Fehlern
              </h3>
              <br />
              <p className="text-muted">
                <b>Sparen Sie nicht an der falschen Stelle!</b> Die
                Maklerprovision liegt häufig deutlich unter dem Betrag, den
                Eigentümer im Alleingang beim Verkauf Ihrer Immobilie
                verschenken. Denn sowohl das Über- als auch das Unterschätzen
                Ihres Immobilienwerts kann negative Auswirkungen auf
                Verkaufsgespräche und den tatsächlichen Verkaufswert der
                Immobilie haben. Zudem können Sie die Maklerkosten vollständig
                auf die Käufer umleiten.
              </p>
            </div>
          </div>

          <div className="row align-items-center py-6 py-md-8">
            <div className="col-12 col-md">
              <h3 className="mb-1">Auf der Suche nach dem richtigen Makler?</h3>
              <p className="fs-lg text-muted">Wir beraten Sie unabhängig.</p>
            </div>
            <div className="col-12 col-md-auto">
              <Link
                to="/de/maklerempfehlung/"
                className="btn btn-primary-soft lift"
              >
                Finden Sie jetzt den richtigen Makler
              </Link>
            </div>
          </div>

          <div className="row justify-content-center pb-6">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card overflow-hidden shadow-lg">
                <LoadableIntroVideo />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative mt-n12">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
          <Curve3 />
        </div>
      </div>

      <section className="py-11 bg-gray-200 border-bottom">
        <div className="container">
          <h2 className="fw-bold text-center mb-5">
            Diese Aufgaben übernimmt ein Immobilienmakler für Sie
          </h2>

          <div className="card-group card-border card-border-lg border-primary shadow-light-lg">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Wertermittlung Ihrer Immobilie
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Abklärung von Bau- und Vertragsrecht
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Erstellung eines aussagekräftigen und attraktiven
                        Exposés
                      </p>
                    </div>

                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Erstellung und professionelle Bearbeitung hochwertiger
                        Bilder Ihrer Immobilie
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Beschaffung & Zusammenstellung aller nötigen Unterlagen
                        (z.B. Energieausweis, Grundbuchauszüge, Grundrisse,
                        Grundsteuerbescheid …)
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Entwicklung einer Marketingstrategie (mit Fokus auf eine
                        spezifische Zielgruppe)
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Bonitätsprüfung und Selektion der Bewerber
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Professionelle Verkaufsverhandlungen
                      </p>
                    </div>

                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Aufsetzung eines Notarvertrags und Kommunikation mit dem
                        Notariat
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Besichtigungsmanagement (Sichtung der Bewerbungen,
                        Terminvereinbarung, Durchführung, Nachbereitung)
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Ordnungsgemäße Übergabe der Immobilie
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">Nachbetreuung des Verkaufs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-11">
        <div className="container">
          <div className="py-3 py-md-4">
            <h2 className="fw-bold">Aktuelle Trends beim Immobilienverkauf</h2>

            <p className="fs-lg text-muted">
              <b>Der Immobilienmarkt boomt</b>, die Preise steigen und der
              Verkauf einer Immobilie gestaltet sich immer komplexer mit immer
              neuen Herausforderungen. Die stetig steigende Nachfrage an
              Wohnraum hat zur Folge, dass aktuell viele Sanierungen und
              Neubauprojekte quasi aus dem Boden gestampft werden. Gleichzeitig
              sehen immer mehr Menschen den Erwerb einer Immobilie als sichere
              Altersvorsorge an.
            </p>
            <p className="fs-lg text-muted">
              <b>Was bedeutet das?</b> Die vielen Marktteilnehmer gestalten den
              Markt zunehmend komplex. Als Eigentümer bzw. Immobilienmakler ist
              es umso wichtiger, jederzeit umfassend über die aktuelle
              Marktsituation informiert zu sein. Eine regelmäßige
              Recherchearbeit ist unumgänglich.
            </p>
            <p className="fs-lg text-muted">
              <b>Eine weitere Herausforderung</b> entsteht durch das starke
              Gefälle zwischen Angebot und Nachfrage. Während die Nachfrage an
              Immobilien in Großstädten und Ballungszentren unverändert hoch
              bleibt, wird es zunehmend schwerer, Immobilien in ländlichen
              Regionen zu veräußern.
            </p>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-12 col-md-7 col-lg-6"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              <Link
                to="/de/maklerempfehlung/"
                className="btn btn-block btn-primary-desat-soft d-flex align-items-center lift"
              >
                Finden Sie jetzt den richtigen Makler{" "}
                <ArrowRight size={15} className="ms-auto" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="pb-6 pb-md-8 border-bottom border-gray-300">
            <Newsletter />
          </div>
        </div>
      </section>
    </>
  )
}

export default ImmobilienmaklerSchweiz
